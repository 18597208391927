<script setup lang="ts">
import { Dayjs } from "dayjs";

const { isDesktop } = useDevice();

const props = withDefaults(
	defineProps<{
		variant?: "tempe" | "topeka";
		timeVariant?: "tempe" | "tlalpan" | "topeka";
		text?: string;
		date?: string | number | Dayjs;
		hideGradient?: boolean;
		iconSize?: number;
		contentColor?: string;
		hideIcon?: boolean;
	}>(),
	{
		variant: "tempe",
		timeVariant: "tempe",
		withGradient: true,
		iconSize: 21,
		contentColor: "var(--chanda)"
	}
);

const iconSize = computed(() => `${props.iconSize}px`);
const color = computed(() => props.contentColor);
const getVariant = computed(() => {
	if (props?.variant) {
		return props.variant;
	}
	return isDesktop ? "tempe" : "topeka";
});
</script>

<template>
	<div class="race-timer">
		<NuxtIcon v-if="!hideIcon" name="20/time" filled />
		<AText :variant="getVariant" class="timer-text" :modifiers="['semibold']">{{ text }}</AText>
		<AText
			:variant="timeVariant"
			class="text-coro"
			:gradient="!hideGradient ? 'var(--goeteborg)' : ''"
			:modifiers="['extrabold']"
		>
			<span>{{ date }}</span>
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.race-timer {
	display: inline-flex;
	align-items: center;
	gap: gutter(0.5);

	&:deep(svg) {
		font-size: v-bind(iconSize);

		path {
			fill: v-bind(color);
		}
	}

	.timer-text {
		color: v-bind(color);
	}
}
</style>
